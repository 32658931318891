.fa.fa-ats-logo-icon:before {
  content: "";
  display: block;
  background-image: url("/images/icon/ATS_Logo.png?v=1.0");
  background-position: center;
  background-size: cover;
  color: #ffffff;
  width: 240px;
  height: 55px;
}
.fa.fa-ats-white-logo-icon:before {
  content: "";
  display: block;
  background-image: url("/images/icon/ATS_White_Logo.png?v=1.0");
  background-position: center;
  background-size: cover;
  color: #ffffff;
  width: 150px;
  height: 55px;
}
.fa.fa-ats-logo-icon {
  margin-top: -5px;
}
