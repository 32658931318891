body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 0px !important;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 30px !important;
  min-height: 84px !important;
  opacity: 1;
  cursor: pointer;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 0 !important;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 0 !important;
}
.men-thumb-item .pro-image-front,
.men-thumb-item .pro-image,
.men-thumb-item .pro-image-back {
  width: 100%;
}
.styled-input input:focus ~ label,
.styled-input input:valid ~ label,
.styled-input select:valid ~ label,
.styled-input select:valid ~ label,
.styled-input textarea:focus ~ label,
.styled-input textarea:valid ~ label {
  font-size: 0.9em;
  color: #00acee;
  top: -1.3em;
  -webkit-transition: all 0.125s;
  -moz-transition: all 0.125s;
  -o-transition: all 0.125s;
  -ms-transition: all 0.125s;
  transition: all 0.125s;
}
.modal-body.modal-body-sub_agile input[type="text"],
.modal-body.modal-body-sub_agile select,
.modal-body.modal-body-sub_agile input[type="number"],
.modal-body.modal-body-sub_agile input[type="email"],
.modal-body.modal-body-sub_agile input[type="password"] {
  font-size: 14px;
  letter-spacing: 1px;
  color: #777;
  padding: 0.5em 1em 0.5em 0;
  border: 0;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  background: none;
  -webkit-appearance: none;
  outline: none;
}
.contact-form input[type="text"],
.contact-form select,
.contact-form input[type="number"],
.contact-form input[type="email"],
.contact-form textarea {
  font-size: 15px;
  letter-spacing: 1px;
  color: #777 !important;
  padding: 0.5em 1em;
  border: 0;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  background: none;
  -webkit-appearance: none;
  outline: none;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: rgba(255, 255, 255, 0) !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}
.agile_top_brand_left_grid:hover .snipcart-details input.button,
.snipcart-details input.button:hover {
  background: #fc636b;
}

.snipcart-details input.button {
  background: #fc636b;
}
.hvr-outline-out {
  background: #fc636b;
}
.hvr-outline-out:before {
  border: #fc636b solid 4px;
}
.item-info-product h4 a {
  color: #002b7a;
}
.link-product-add-cart {
  background: #002b7a none repeat scroll 0 0;
}
.footer {
  background: #002b7a;
}
.footer-left p {
  color: #ffffff;
}
.sign-gd ul li a,
.sign-gd-two ul li a {
  color: #ffffff;
}
.sign-gd ul li a:hover,
.sign-gd-two ul li a:hover {
  /* color: #fc636b; */
}
.w3-address-right p {
  color: #ffffff;
}
.w3-address-right p a {
  color: #fc636b;
}

.w3-address-grid {
  background: #002b7a;
}
p.copy-right {
  color: #ffffff;
}
p.copy-right {
  margin-top: 20px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .modal.fade.show {
  opacity: 1;
} */
.header ul li {
  width: 50%;
}
/* .header-middle form input[type="submit"] {
    background: url(../images/search.png) no-repeat 4px 0px #002b7a;
} */
ul.multi-column-dropdown li a {
  font-size: 1em;
}
.navbar-nav > li {
  margin: 0 20px;
}
.menu__list {
  /* margin: 0 12%; */
}
.contact-form {
  background: #e8f0fe;
  padding: 3em;
}
.header {
  background: #002b7a;
}
.header ul li i {
  margin-right: 12px;
  top: 2px;
  color: #ee0000;
}
.ban-top {
  background: #002b7a;
  padding: 0px 0;
}
.address-grid h4,
h4.black-form-head {
  font-weight: 700;
  font-size: 2em;
  text-transform: uppercase;
  color: #181919;
  letter-spacing: 1px;
  margin-bottom: 1em;
}
.black-w3ls {
  color: #000 !important;
}
ul.multi-column-dropdown li a {
  padding-left: 8px;
}
h4.black-form-head span {
  font-weight: 300;
}
.flex-control-thumbs li {
  width: 24.2%;
}
.contact-grid-agile-w3 {
  /* width: 49%; */
}
.logo_agile h1 a {
  font-weight: unset;
  color: #000;
  letter-spacing: unset;
  font-size: 1.3em;
  line-height: unset;
  position: relative;
  padding: 5px;
}
#home-brand ul {
  /* margin-bottom: 35px; */
}
#home-brand .react-multi-carousel-track {
  /* height: 220px; */
}
#home-brand .react-multi-carousel-track {
  /* margin-bottom: 134px; */
}
.header-middle form input[type="button"] {
  background: url(./search.png) no-repeat 4px 0px #002b7a;
  width: 11%;
  height: 50px;
  border: none;
  padding: 0;
  border: none;
  outline: none;
}
@media (max-width: 667px) {
  .header ul li {
    display: inline-block;
    width: 100%;
  }
  .multi-gd-img {
    display: none;
  }
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-r-30 {
  padding-right: 30px;
}

.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-25 {
  margin: 25px;
}
.m-30 {
  margin: 30px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-r-30 {
  m--right: 30px;
}

label.ant-checkbox-wrapper span {
  font-weight: 400 !important;
}
.carousel.carousel-slider .control-arrow:hover {
  background: rgb(0 0 0 / 0%) !important;
}
select option {
  padding: 10px;
}
.modal-body.modal-body-sub_agile button[type="submit"],
.back-btn {
  border: none;
  padding: 10px 40px 10px;
  font-size: 14px;
  outline: none;
  text-transform: uppercase;
  margin: 0 0 0 -4px;
  font-weight: 700;
  letter-spacing: 1px;
  background: #111;
  color: #fff;
}
.page-head_agile_info_w3l h3 {
  color: #00acee;
}
.modal-body.modal-body-sub_agile button[type="submit"]:hover,
.back-btn:hover {
  background: #00acee;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
